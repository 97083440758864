@import "./icons.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// RTL Mode
// @import "./bootstrap.scss";
// @import "./app-rtl.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";
/* Chrome, Safari, Edge, Opera */
// @page {
//   size: A4;
//   // margin: 0;
// }
@media print {
  .slip-image {
    width: 25rem !important;
  }
  .table-responsive {
    display: block !important;
    overflow-x: visible !important;
  }
  .invoice-body {
    border: none !important;
    justify-content: center !important;
    .header {
      background: #f3f3f3 !important;
      .invoice-header {
        img {
          width: 25rem !important;
        }
        .title {
          h4 {
            color: black !important;
          }
        }
      }
    }
  }
}
.billing-page {
  height: 100vh !important;
}
.table-select {
  width: 100%;
  height: 36px;
  border: none;
}
select[disabled] {
  color: black;
}
.text-opinenumber {
  color: #f50501;
}
.text-invoicenumber {
  color: #017ff5;
}
.text-approved {
  color: #029745;
}
.text-draft {
  color: #879697;
}
.text-overdue {
  color: #ff660f;
}
.printBtn {
  background: white;
  border: 1px solid grey;
  padding: 6px;
}
.status-btn {
  background: #1c6180 !important;
  border: none !important;
  color: white !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.modal-dialog-scrollable {
  .modal-body {
    scrollbar-width: thin;
  }
}
.hsnsac {
  .hsnsacinput {
    width: 100%;
    height: 35px;
  }
  .text {
    margin-top: 35px;
    text-align: center;
    color: #7b7b7b;
    font-size: 12.5px;
  }
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}
.dflex {
  display: flex;
}
.d-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #052f85;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px !important;
}
.align-center {
  display: flex;
  align-items: center;
  i {
    font-size: 16px;
    color: green;
    margin-left: 5px;
  }
}
.authentication-bottom-links {
  display: flex;
  justify-content: space-between;
}
.jsc {
  display: flex;
  justify-content: space-between;
}
.copy-address {
  cursor: pointer;
  color: #00acfa;
}
.b {
  color: #00acfa;
}
i {
  // color: #2485e8;
  font-size: 16px;
  cursor: pointer;
}
input,
select {
  &:hover {
    outline: 0;
  }
  &:active {
    outline: 0;
  }
  &:focus {
    outline: 0;
  }
  border: 1px solid #dddddd;
}
textarea {
  &:hover {
    outline: 0;
  }
  &:active {
    outline: 0;
  }
  &:focus {
    outline: 0;
  }
  border: 1px solid #dddddd;
}
.cursor-pointer {
  cursor: pointer;
}
.bold {
  font-weight: bold;
}
.inr {
  background: green;
  padding: 0px 5px;
  color: white;
}
.noti-icon .badge {
  left: 23px;
}
.dataTables_filter,
.dataTables_paginate {
  float: right;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 30px;
}
// kanban board
.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.task-box {
  border: 1px solid $border-color;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.ReactModal__Overlay {
  z-index: 1001 !important;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}
.external-event {
  &:hover {
    cursor: pointer;
  }
}
a:hover {
  cursor: pointer;
}
.rating-container {
  background-color: transparent !important;
}
.input-group-append {
  z-index: 0;
}
.input-color {
  color: $input-color !important;
}
.sketch-picker {
  position: absolute;
  z-index: 1;
}
.rangeslider__fill {
  background-color: $primary !important;
}

//---------

.fp-left {
  background: #2650f6;
  height: 100vh;
}
.fp-form {
  width: 35%;
  h5 {
    color: black;
    font-weight: 600;
    margin-bottom: 3em;
  }
  h6 {
    font-weight: 600;
    margin-bottom: 2em;
  }
  p {
    margin-bottom: 4em;
  }
}
.fp-right {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .formChange {
    position: absolute;
    top: 6%;
    right: -7%;
    span {
      font-weight: 600;
      color: #2650f6;
    }
  }
}
.btn-full-main {
  background: #2650f6;
  width: 100%;
  height: 3.2em;
  margin-top: 1em;
}
.btn-full-gray {
  background: #eeeeee;
  width: 100%;
  height: 3.2em;
  color: #2f2f2f !important;
  border: 1px solid #d7d2d2;
}
.btn-icon-main {
  padding: 0;
  width: 25px;
  margin-right: 1em;
}
.fp-link {
  float: right;
  margin: 1em 0;
}
.fp-left {
  h6 {
    color: white;
    margin-bottom: 40%;
  }
  h3 {
    color: white;
    font-weight: 600;
    margin-bottom: 2em;
    font-size: 22px;
  }
  p {
    margin-top: 20em;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  .fp-left-container {
    width: 85%;
  }
}
.primary-btn {
  background: #2650f6;
  border: none;
  display: flex;
  align-items: center;
  i {
    margin-right: 0.65em;
    font-size: 15px;
  }
}
.side-i {
  background: #a5b2f2 !important;
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-right: 12px;
  border-radius: 4px;
  font-size: 15px !important;
  color: white !important;
}
.vertical-menu {
  background: #f7f7fa;
}
.sidebar-a {
  color: #646060 !important;
}
.mm-active > i {
  color: #556ee6 !important;
}
.form {
  width: 100%;
}
.formSubHeading {
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 1em;
  display: block;
  color: #919295;
}
.rightHr {
  border-left: 1px solid black;
}
// customer single view overview form
.formLeft {
  border-right: 1px solid #e8e8e8;
}
.formRight {
  padding: 20px 20px 50px;
  vertical-align: top;
}
.hover-text {
  color: #eb4848;
  margin-right: 15px;
  font-size: 14px;
  font-weight: 500;
}
.tooltip {
  z-index: 999999;
}
.overviewTop {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: 0;
  margin-top: 0;
  line-height: 1.6;
  -webkit-text-size-adjust: 100%;
}
.overviewTopli {
  h6 {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.1;
  }
  span {
    font-size: 20px;
    font-weight: 600;
    padding-top: 12px;
    // color: #ff660f;
  }
  border-right: 1px solid #ddd;
  margin-left: -4px;
  width: 44%;
  list-style-type: none;
  display: grid;
  text-align: left !important;
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}
.overviewTopliright {
  .overviewToplirightdivUC {
    font-weight: 400;
    font-size: 14px;
    padding: 1% 1% 1% 15px;
    display: block;
    .overviewToplirightdivspan1 {
      display: inline-block;
      width: 165px;
      color: #777 !important;
      font-weight: 400;
      font-size: 14px;
    }
    .overviewToplirightdivspan2 {
      font-weight: 600;
      cursor: pointer;
      font-size: 14px;
    }
    .overviewToplirightdivspan2pdp {
      font-weight: 600;
      font-size: 14px;
      box-sizing: border-box;
    }
    display: grid;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    text-align: left !important;
    display: grid;
  }
}
.overviewRight {
  // display: flex;
  margin: auto;
  // flex: right;
  justify-content: space-between;
  .overviewCompanyDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .companyName {
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 500;
    }
    .aboutCompany {
      display: block;
      text-transform: uppercase;
      color: #9b9b9b;
      font-size: 12px;
      .aboutCompanytext {
        color: black;
        font-size: 14px;
        text-transform: capitalize;
      }
    }
  }
}
.center {
  // padding: auto;
  // display: flex;
  width: 33.33%;
  // align-items: center;
  // justify-content: center;
}
.ovRightTable {
  margin-top: 10px;
  padding-left: 15px !important;
  box-sizing: border-box;
  display: block;
  .ovRightTablediv {
    margin-top: 30px;
    border-bottom: none;
    .ovTableTopbar {
      box-sizing: border-box;
      display: block;
      .ovTableTopbarSpan {
        margin-right: 10px;
        font-size: 16px;
        color: #777 !important;
        font-weight: 300 !important;
        float: left !important;
      }
      .ovTableIncomeRightside {
        font-size: 12px;
        float: right !important;
      }
    }
  }
}

// overview style end
.mailformLeft {
  border-right: 1px solid #e8e8e8;
}

// Statement form styles
.statements {
  background-attachment: local, local, scroll, scroll;
  padding-bottom: 0 !important;
  box-sizing: border-box;
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #212529;
  text-align: left;
}
.textCenter {
  border-bottom: none;
  padding-bottom: 9px;
  margin: 40px 0 20px;
  text-align: center !important;
}
.detailContainer {
  position: relative;
  margin-bottom: 35px;
  box-shadow: 0 0 6px #ccc;
  align-self: center;
}
.details-container {
  align-self: center;
  align-items: center;
}
.trclass_evenrow {
  background-color: #f6f5f5;
}
.trclass_oddrow {
  background-color: #ffffff;
}

table {
  -fs-table-paginate: paginate;
  .allignCentre {
    text-align: centre;
  }
}
.title-section {
  float: right;
  margin-top: 20px;
}
.rtl .title-section {
  float: left;
}
.pcs-itemtable-header {
  padding: 4px 4px;
}
.summary-section {
  float: right;
}
.rtl .summary-section {
  float: left;
}
.box-padding {
  padding: 8px 4px;
}
@font-face {
  font-family: "WebFont-Ubuntu";
  src: local(Ubuntu),
    url(https://fonts.gstatic.com/s/ubuntu/v10/4iCs6KVjbNBYlgoKcg72nU6AF7xm.woff2);
}

.pcs-template {
  font-family: Ubuntu, "WebFont-Ubuntu";
  font-size: 9pt;
  color: #333333;
  background: #ffffff;
}

.pcs-header-content {
  font-size: 9pt;
  color: #333333;
  background-color: #ffffff;
}
.pcs-template-body {
  padding: 0 0.4in 0 0.55in;
}
.pcs-template-footer {
  height: 0.7in;
  font-size: 6pt;
  color: #aaaaaa;
  padding: 0 0.4in 0 0.55in;
  background-color: #ffffff;
}
.pcs-footer-content {
  word-wrap: break-word;
  color: #aaaaaa;
  border-top: 1px solid #adadad;
}

.pcs-label {
  color: #333333;
}
.pcs-entity-title {
  font-size: 16pt;
  color: #000000;
}
.pcs-orgname {
  font-size: 10pt;
  color: #333333;
}
.pcs-customer-name {
  font-size: 9pt;
  color: #333333;
}
.pcs-eori-number {
  color: #333;
  margin: 0;
  padding-top: 10px;
}
.pcs-itemtable-header {
  font-size: 9pt;
  color: #ffffff;
  background-color: #3c3d3a;
}
.pcs-itemtable-breakword {
  word-wrap: break-word;
}
.pcs-taxtable-header {
  font-size: 9pt;
  color: #ffffff;
  background-color: #3c3d3a;
}
.breakrow-inside {
  page-break-inside: avoid;
}
.breakrow-after {
  page-break-after: auto;
}
.pcs-item-row {
  font-size: 9pt;
  border-bottom: 1px solid #adadad;
  background-color: #ffffff;
  color: #000000;
}
.pcs-item-sku,
.pcs-item-hsn,
.pcs-item-coupon,
.pcs-item-serialnumber {
  margin-top: 2px;
  font-size: 10px;
  color: #444444;
}
.pcs-item-desc {
  color: #727272;
  font-size: 9pt;
}
.pcs-balance {
  background-color: #f5f4f3;
  font-size: 9pt;
  color: #000000;
}
.pcs-savings {
  font-size: 0pt;
  // color: ;
  // background-color: ;
}
.pcs-totals {
  font-size: 9pt;
  color: #000000;
  background-color: #ffffff;
}
.pcs-notes {
  font-size: 8pt;
}
.pcs-terms {
  font-size: 8pt;
}
.pcs-header-first {
  background-color: #ffffff;
  font-size: 9pt;
  color: #333333;
  height: auto;
}

.pcs-status {
  //  color: ;
  font-size: 15pt;
  border: 3px solid;
  padding: 3px 8px;
}
.billto-section {
  padding-top: 0mm;
  padding-left: 0mm;
}
.shipto-section {
  padding-top: 0mm;
  padding-left: 0mm;
}
label {
  font-weight: 400;
  color: #a1a1a1;
}
@page :first {
  @top-center {
    content: element(header);
  }
  margin-top: 0.7in;
}

.pcs-template-header {
  padding: 0 0.4in 0 0.55in;
  height: 0.7in;
}

.pcs-template-fill-emptydiv {
  display: table-cell;
  content: " ";
  width: 100%;
}

/* Additional styles for RTL compat */

/* Helper Classes */

.inline {
  display: inline-block;
}
.v-top {
  vertical-align: top;
}
.text-align-right {
  text-align: right;
}
.rtl .text-align-right {
  text-align: left;
}
.text-align-left {
  text-align: left;
}
.rtl .text-align-left {
  text-align: right;
}
.float-section-right {
  float: right;
}
.rtl .float-section-right {
  float: left;
}
.float-section-left {
  float: left;
}
.rtl .float-section-left {
  float: right;
}

/* Helper Classes End */

.item-details-inline {
  display: inline-block;
  margin: 0 10px;
  vertical-align: top;
  max-width: 70%;
}

.total-in-words-container {
  width: 100%;
  margin-top: 10px;
}
.total-in-words-label {
  vertical-align: top;
  padding: 0 10px;
}
.total-in-words-value {
  width: 170px;
}
.total-section-label {
  padding: 5px 10px 5px 0;
  vertical-align: middle;
}
.total-section-value {
  width: 120px;
  vertical-align: middle;
  padding: 10px 10px 10px 5px;
}
.rtl .total-section-value {
  padding: 10px 5px 10px 10px;
}

.tax-summary-description {
  color: #727272;
  font-size: 8pt;
}

.bharatqr-bg {
  background-color: #f4f3f8;
}

/* Overrides/Patches for RTL compat */
.rtl th {
  text-align: inherit; /* Specifically setting th as inherit for supporting RTL */
}
/* Overrides/Patches End */

/* Subject field styles */
.subject-block {
  margin-top: 20px;
}
.subject-block-value {
  word-wrap: break-word;
  white-space: pre-wrap;
  line-height: 14pt;
  margin-top: 5px;
}
/* Subject field styles End*/

.pcs-sub-label {
  color: #666;
  font-size: 10px;
}
.pcs-hsnsummary-compact {
  padding: 0;
  margin-top: 3px;
}
.pcs-hsnsummary-label-compact {
  margin-bottom: 3px;
  font-weight: 600;
  padding-left: 3px;
  font-size: 9pt;
}
.pcs-hsnsummary-header-compact {
  text-align: right;
  padding: 5px 7px 2px 7px;
  word-wrap: break-word;
  width: 17%;
  height: 32px;
  border-right: 1px solid #9e9e9e;
  font-size: 8pt;
  font-weight: 600;
}
.pcs-hsnsummary-body-compact,
.pcs-hsnsummary-total-compact {
  text-align: right;
  word-wrap: break-word;
  font-size: 7pt;
  padding: 4px 10px;
}
.pcs-hsnsummary-total-compact {
  border-top: 1px solid #adadad;
}
.pcs-ukvat-summary {
  margin-top: 50px;
}
.pcs-ukvat-summary-header {
  padding: 5px 10px 5px 5px;
}
.pcs-ukvat-summary-header:first-child {
  padding-left: 10px;
}
.pcs-ukvat-summary-label {
  font-size: 10pt;
}
.pcs-ukvat-summary-table {
  margin-top: 10px;
}
.pcs-ukvat-summary-body,
.pcs-ukvat-summary-total {
  padding: 10px 10px 5px 10px;
}
.pcs-ukvat-summary-body:first-child {
  padding-bottom: 10px;
  padding-right: 0;
}
.pcs-payment-block {
  margin-top: 20px;
}
.pcs-payment-block-inner {
  margin-top: 10px;
}
.tab-content {
  padding: 1em;
}
.noti {
  position: relative;
  .dot {
    width: 7px;
    height: 7px;
    background: red;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    right: 2px;
  }
}
.user-profile {
  text-align: right;
}
.header-profile-user {
  border-radius: 6px;
  padding: 1px;
  background-color: grey;
}
.navbar-brand-box {
  text-align: start;
}
.bottom-bar {
  position: fixed;
  z-index: 9999999;
  right: 0;
  bottom: 0;
  left: 0;
  background: #141515;
  height: max-content;
  padding: 8px;
  text-align: center;

  .btn-left {
    background: transparent;
    border-radius: 3px;
    font-size: 12px;
    color: white;
    width: 100%;
    border: 1px solid white;
  }
  .printView {
    justify-content: center;
    display: flex;
    align-items: center;
    .print {
      color: white;
      cursor: pointer;
    }
  }
  .btn-right {
    background: #18a318;
    border-radius: 3px;
    font-size: 12px;
    color: white;
    width: 25%;
  }
}
// .card {
//   margin-bottom: 50px;
// }
// .footer {
//   margin-bottom: 40px;
// }
.card-body {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(128, 128, 128, 1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(128, 128, 128, 1);
  box-shadow: 0px 0px 10px 0px rgba(128, 128, 128, 1);
}
.form {
  label {
    color: black;
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
  }
  ::placeholder {
    font-size: 12px;
  }
}
.total-amount {
  color: black;
  font-size: 24px;
  font-weight: 600;
}
.selected-address {
  background: #f4f2d7;
  padding: 10px;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 15px;
  p {
    font-weight: 600;
  }
  .sel-address {
    p {
      font-weight: 400;
    }
  }
  .btn-address {
    p {
      font-size: 11px;
      margin-bottom: 0;
    }
    .btn {
      .btn-use {
        background: #556ee6;
        padding: 6px;
        border-radius: 14px;
        color: white;
      }
    }
    .btn-end {
      text-align: right;
      align-content: center;
      display: grid;
      color: #556ee6;
      cursor: pointer;
      p {
        font-weight: 500;
      }
    }
  }
}
.invoice-table {
  thead {
    th {
      border-top: 0 !important;
    }
  }
  tbody {
    tr {
      td {
        padding: 7px;
        input {
          border: none;
          padding: 0;
        }
      }
    }
  }
}
.table-btn {
  background: white;
  border: 1px solid black;
  border-radius: 25px;
  padding: 5px 11px;
  margin-right: 15px;
  margin-bottom: 25px;
  &:focus {
    box-shadow: none !important;
  }
}
.attach {
  margin-top: 15px;
  input {
    width: 70%;
    border: 1px solid #e1e7f2;
    padding: 7px;
    border-radius: 5px;
  }
}
.inv-details {
  margin-top: 15px;
  .adj {
    width: 70%;
  }
  .totals {
    margin-top: 50px;
    .total {
      font-weight: 600;
    }
    .tdesc {
      font-weight: 600;
    }
  }
}
.r-payment {
  margin-right: 20px;
}
.gateway {
  i {
    margin-right: 5px;
  }
}
.form-group {
  margin-bottom: 0.5rem;
}
.dashboard-content {
  position: fixed;
  top: 50%;
  left: 50%;
}
.addlinks {
  text-decoration: underline;
}
.input-file {
  input[type="file"] {
    display: none;
  }
  .custom-file-upload {
    i {
      font-size: 22px;
    }
    background: #ddd;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 12px 12px;
    cursor: pointer;
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  font-size: 12px;
  color: #000000;
  border-top: none;
  border-left: none;
  border-bottom: 4px solid blue;
  border-right: none;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: none;
}
.tax {
  display: flex;
  align-items: baseline;
  i {
    margin-left: 10px;
    font-size: 20px;
    color: orange;
  }
}
.taxrates {
  margin-top: 10px;
}
.trackinv {
  color: gray;
  font-size: 12px;
}
.tabcard {
  padding-left: 0;
  padding-right: 0;
}
.iitem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: 2em;
  .itembtn {
    float: right;
    .ddtoggle {
      padding: 0;
      margin: 0;
      border: none;
    }
    .request-approv-btn {
      background: yellow !important;
      border: none;
    }
    .newbtn {
      background: #34c38f;
      color: white;
      border: none;
    }
    .bdr {
      border-right: 1px solid #b7abab;
      margin-right: 15px;
      padding: 5px;
    }
    .pagetips {
      font-size: 14px;
      color: black;
    }
    button {
      border: 1px solid #cdcdcd;
      margin-right: 15px;
      padding: 5px 15px;
      border-radius: 3px;
      font-size: 14px;
      color: black;
    }
  }
}
.itemview-modal {
  .modal-content {
    .modal-head {
      button {
        margin-right: 20px;
      }
    }
    .form {
      div {
        margin-top: 10px;
        .head {
          font-weight: 400;
          color: #777;
        }
      }
      .itempur-info {
        font-size: 18px;
        color: black;
        font-weight: 500;
      }
    }
  }
}
.export-modal {
  .modal-content {
    border-radius: 0;
    padding: 15px;
    .modal-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      i {
        font-size: 16px;
        cursor: pointer;
      }
    }
    .exportbtn {
      border: none;
      padding: 5px 20px;
      background: green;
      color: white;
      margin-top: 25px;
    }
  }
}
.exportuserbtn {
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
  background: green;
  color: white;
  margin-top: 25px;
}
.tablebtn {
  button {
    border: none;
    border-radius: 4px;
    padding: 3px 15px;
    background: #34c38f;
    color: white;
    font-size: 12px;
    font-weight: 600;
    &:disabled {
      background: grey;
      cursor: no-drop;
    }
  }
}
.btn-round {
  margin-right: 7px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0.8em;
  &.edit {
    background: #2650f6a1;
    i {
      color: white;
      font-size: 18px;
    }
  }
  &.delete {
    background: #f5696947;
    i {
      color: red;
      font-size: 18px;
    }
  }
}
.tabledltbtn {
  button {
    // border: #f46a6a;
    // border-radius: 4px;
    // padding: 4px 4px;
    // background: white !important;
    // color: #f46a6a !important;
    // font-size: 12px;
    // font-weight: 600;
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #f46a6a;
    background-color: #fff;
    cursor: pointer;
    // box-shadow: 0 0 10px #333;
  }
  &:hover {
    background-color: rgb(245, 207, 207);
    // transform: scale(1.2);
    box-shadow: 0 0 4px #111;
    border-radius: 20px;
    transition: 0.3s;
  }
}
.item-head {
  .bbb {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
  div {
    .ddtoggle {
      padding: 0;
      margin: 0;
      border: none;
    }
    button {
      i {
        font-size: 15px;
      }
      .fa-times {
        color: red;
      }
      border: 1px solid #cdcdcd;
      margin-right: 15px;
      padding: 0 15px;
      border-radius: 3px;
      font-size: 14px;
      color: black;
      height: 40px;
      background-color: white;
      &.close-btn {
        border-color: red;
      }
    }
  }
}
.itemview {
  div {
    margin-top: 10px;
    .head {
      font-weight: 500;
      color: rgb(68, 67, 67);
    }
  }
  .itempur-info {
    font-size: 18px;
    color: black;
    font-weight: 500;
  }
  margin-bottom: 30px;
}
.pricelist {
  .row {
    // margin-bottom: 10px;
    .label {
      font-size: 14px;
      font-weight: 600;
    }
  }
}
.switch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  span {
    font-style: italic;
    margin-right: 10px;
    font-weight: 500;
  }
}
.pricecustomimport {
  p {
    text-transform: uppercase;
  }
  ul {
    margin-bottom: 30px;
    li {
      span {
        font-weight: 600;
      }
    }
  }
}
.pricebtns {
  display: flex;
  align-items: center;
  div {
    margin-right: 20px;
    margin-left: 20px;
  }
}
.btn-link {
  display: flex;
  align-items: center;
  i {
    font-size: 16px;
    margin-right: 5px;
  }
  span {
    text-decoration: none;
    color: #2485e8;
  }
}
.formik {
  .formik-tabtextarea {
    &:hover {
      outline: 0;
    }
    &:active {
      outline: 0;
    }
    &:focus {
      outline: 0;
    }
    width: 100%;
    height: 80px;
    border: 1px solid #dddddd;
  }
  .formik-tabinput {
    &:hover {
      outline: 0;
    }
    &:active {
      outline: 0;
    }
    &:focus {
      outline: 0;
    }
    width: 100%;
    height: 35px;
    border: 1px solid #dddddd;
  }
  .formik-rowName {
    display: flex;
    .inputs {
      margin-right: 10px;
      height: 35px;
    }
  }
  .textarea {
    width: 55%;
    height: 70px;
  }
  .table-inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .row {
    margin-bottom: 15px;
  }
  .formik-radiodiv {
    margin-bottom: 10px;
    span {
      margin-left: 5px;
    }
  }
  .formik-input {
    &:hover {
      outline: 0;
    }
    &:active {
      outline: 0;
    }
    &:focus {
      outline: 0;
    }
    width: 35%;
    height: 35px;
    border: 1px solid #dddddd;
  }
  .formik-inputrow {
    width: 75%;
  }
  .terms-textarea {
    width: 100% !important;
  }
  .formik-textarea {
    &:hover {
      outline: 0;
    }
    &:active {
      outline: 0;
    }
    &:focus {
      outline: 0;
    }
    width: 35%;
    border: 1px solid #dddddd;
    height: 75px;
  }
  .formik-input-search {
    display: flex;
    align-items: center;
    div {
      margin-left: 5px;
      i {
        font-size: 16px;
        color: green;
      }
    }
  }
}
.add-tablerow {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e9e9e9;
  border: 1px solid #e9e9e9;
  padding: 5px 11px;
  border-radius: 0;
  margin-right: 15px;
  margin-bottom: 25px;
  color: black;
  i {
    margin-right: 5px;
    color: #052f85;
    font-size: 18px;
  }
}
.status {
  background-color: #00acfa;
  padding: 3px 10px;
  color: white;
}
.display-journal {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  i {
    font-size: 28px;
    color: #00acfa;
  }
}
.table-left {
  display: grid;
  justify-content: space-between;
  .customernotes {
    display: grid;
    align-items: end;
  }
}
.table-right {
  background: #fbfafa;
  padding: 15px;
  border-radius: 10px;
  .details {
    display: flex;
    justify-content: space-between;
  }
  select {
    width: 100%;
  }
  input,
  select {
    height: 30px;
  }
  .detail-head {
    font-weight: 600;
  }
  .total {
    font-weight: 600;
    font-size: 18px;
    color: black;
  }
  .input-tooltip {
    display: flex;
    align-items: center;
    i {
      font-size: 16px;
      font-weight: 300;
      margin-left: 5px;
    }
  }
}
.table-bottom {
  .retinvoice {
    display: flex;
    align-items: center;
  }
}
.invoiced {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: green;
}
.yellow-bar {
  width: 5px;
  background-color: #fba800;
  height: auto;
}
.table-sel {
  padding: 0;
  .table-select {
    border: none;
    width: 100%;
  }
}
.invoice {
  .address {
    span {
      line-height: 2em;
      text-transform: uppercase;
    }
  }
  .invoice-title {
    display: flex;
    justify-content: space-between;
  }
  .details {
    text-align: right;
  }
  .inwords {
    .rs {
      font-style: italic;
    }
  }
  .totals {
    .row {
      margin-bottom: 15px;
    }
  }
}
.overviewLeft {
  .header-profile-user {
    width: 100%;
    height: 100%;
  }
  .custname {
    display: flex;
    justify-content: space-between;
    span {
      font-size: 14px;
      font-weight: 500;
    }
    a {
      font-weight: 500;
    }
  }
  .profiledit {
    display: flex;
    // justify-content: space-between;
    font-size: 12px;
    color: #2485e8;
    margin-top: 5px;
    small {
      margin-right: 5px;
      margin-left: 5px;
    }
    div {
      cursor: pointer;
    }
  }
  .head {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 1px solid #ced4da;
    i {
      font-size: 22px;
    }
  }
  .address {
    margin-bottom: 20px;
    span {
      font-size: 14px;
    }
    .addaddress {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      line-height: 1.7;
      span {
        font-size: 13px;
        color: #777;
      }
      .new {
        color: #2485e8;
        cursor: pointer;
      }
    }
  }
  .otherdetails {
    div {
      margin-bottom: 10px;
      i {
        font-size: 12px;
      }
    }
  }
}
.loader {
  width: 18px;
}
.Toastify__toast-container--bottom-center {
  bottom: 2.5em !important;
}
.tableLoading {
  margin-left: 20px;
}
.btn-close {
  background: transparent
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E")
    50%/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  box-sizing: content-box;
  color: #000;
  height: 1em;
  opacity: 0.5;
  padding: 0.25em;
  width: 1em;
}
.selected_customer {
  .selected_customer_details {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #2485e8;
    font-size: 12px;
    margin-top: 3px;
    i {
      margin-right: 3px;
    }
  }
  .selected_customer_address {
    display: flex;
    margin-top: 30px;
    .selected_address {
      .bhead {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        i {
          margin-left: 3px;
        }
        h6 {
          margin: 0px !important;
        }
      }
      .bbody {
        display: flex;
        flex-direction: column;
        color: black;
        margin-top: 10px;
      }
      .newAddress {
        margin-top: 7px;
        color: #4396eb;
        cursor: pointer;
      }
    }
    .shipping {
      margin-left: 150px;
    }
  }
  .selected_customer_gst {
    margin-top: 40px;

    display: flex;
    align-items: center;
    i {
      margin-left: 3px;
      color: #2485e8;
    }
  }
}
.right-bar {
  width: 400px !important;
  .sidebar_head {
    background: #34c38f2b;
    display: flex;
    .profile_pic {
      width: 80px;
      height: 80px;

      img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .profile {
      margin-left: 10px;
      position: relative;
      top: 10px;
      .name {
        font-size: 18px;
        font-weight: 600;
        color: black;
      }
    }
  }
  .sidebar_body {
    .body {
      display: flex;
      justify-content: space-between;
      h6 {
        color: black;
        font-size: 22px;
        margin-top: 10px;
      }
    }
    .other {
      display: flex;
      align-items: center;
      margin-top: 20px;
      color: #2485e8;
      cursor: pointer;

      i {
        margin-left: 3px;
        font-size: 10px;
      }
    }
    .other_details {
      margin-top: 15px;
      color: black;
      .row {
        margin-bottom: 15px;
      }
      span {
        color: #495057;
      }
    }
  }
  .rightbar_address {
    .bhead {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      i {
        margin-right: 3px;
        font-size: 20px;
        color: #28a745;
      }
      h6 {
        margin: 0;
        color: black;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 14px;
      }
    }
    .bbody {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      span {
        line-height: 1.6;
        color: black;
      }
    }
  }
  .rightbar_contact {
    display: flex;
    margin-bottom: 15px;
    .contact_pic {
      width: 40px;
      height: 40px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .contact_address {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      span {
        line-height: 1.8;
      }
      h6 {
        color: black;
        font-weight: 500;
      }
    }
  }
  .verti-timeline {
    margin: 0 10px 50px !important;
    .timeline-time {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 11px;
      }
    }
  }
}
.tab-fs {
  font-size: 12px;
}
.popover {
  width: 300px;
  .popover-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  select {
    width: 100%;
    height: 30px;
    font-size: 13px;
    margin-bottom: 10px;
  }
  input[type="text"] {
    width: 100%;
    height: 30px;
  }
  .permanent {
    margin: 25px 0px 10px;
    h6 {
      color: black;
    }
    label {
      font-weight: 400;
      font-size: 12px;
      input {
        margin-right: 5px;
      }
    }
  }
}
.billing {
  max-width: 500px !important;
  .popover-billing {
    border-bottom: 1px solid #d2d2d2;
    display: flex;
    justify-content: space-between;
    &:hover {
      background: rgba(54, 193, 142, 0.13);
    }
    .pop-billing {
      display: flex;
      flex-direction: column;
      span {
        color: black;
        font-size: 13px;
      }
    }
  }
}

.popover-footer {
  font-size: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px;
  background: #ededed;
  color: #55a0ed;
  i {
    margin-right: 3px;
  }
}
.modal-billing {
  input {
    width: 100%;
    height: 30px;
  }
  select {
    width: 100%;
    height: 30px;
    background-color: white;
  }
  textarea {
    width: 100%;
  }
}
.search-form {
  overflow-y: visible;
}
.customer-search {
  max-width: 600px !important;
  margin: 0 auto;
  .customer-search-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    input,
    select {
      height: 30px;
    }
  }
}
.data-table {
  overflow-y: scroll;
  scrollbar-width: thin;
}
.extra-item {
  position: absolute;
  width: 350px;
  right: 33px;
  top: 10rem;
  .item-card {
    border-radius: 12px;
    box-shadow: 0px 0px 7px 0px #e7e6e6 !important;
    padding: 0.7rem !important;
  }
}
.singlepage {
  // display: flex;
  // justify-content: center;
  .card {
    width: 100%;
    .single-main {
      border: 1px solid black;
      padding: 30px;
      .single-header {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        .address {
          display: flex;
          flex-direction: column;
        }
        .title {
          font-size: 1.5rem;
          color: black;
          font-weight: 600;
        }
      }
      .details {
        display: flex;
        .column {
          flex-direction: column;
          display: flex;
        }
      }
      .totals {
        line-height: 2;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
      }
      .signature {
        height: 80px;
        display: flex;
        align-items: end;
        justify-content: center;
      }
    }
  }
}
.checkbox-div {
  display: flex;
  justify-content: space-between;
  .custom-checkbox {
    position: relative;
    background: white;
    border: 1px solid #dadada;
    padding: 5px;
    height: 10em;
    width: 10em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 15px;
    box-shadow: -2px 0px 6px 0px rgba(181, 181, 181, 1);
    cursor: pointer;
    &:hover {
      background: linear-gradient(
        rgb(240, 241, 244),
        rgb(235, 238, 246),
        rgb(230, 235, 248),
        rgb(225, 233, 249),
        rgb(219, 230, 251)
      );
    }
    .checkbox-round {
      width: 1em;
      height: 1em;
      position: absolute;
      top: 0.5em;
      left: 0.5em;
      border-radius: 50%;
      border: 1px solid black;
      display: flex;
      justify-content: center;
      align-items: center;
      .checkbox-inner-round {
        width: 0.5em;
        height: 0.5em;
        background: #18a318;
        border-radius: 50%;
      }
    }

    i {
      font-size: 4em;
      position: absolute;
      top: 0.6em;
    }
    .checkbox-name {
      position: absolute;
      bottom: 0.5em;
      text-align: center;
    }
  }
}
.checkout-right {
  .checkout-none {
    border: 1px solid;
    height: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .checkout-pdf {
    border: 1px solid;
    height: 100%;
    border-radius: 10px;
    padding: 10px;
    h4 {
      color: black;
      font-weight: 600;
    }
  }
}
.black {
  margin-bottom: 5px;
  color: #000;
}
.letterImage {
  background: #2650f6;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  span {
    color: white;
    font-weight: 600;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
.header-image {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff5200b3 !important;
  font-size: 22px !important;
}
.slip-image {
  width: 16rem;
}
.slip-image-login {
  width: 18rem;
  margin-bottom: 2em;
}

.jsa {
  display: flex;
  justify-content: space-around;
}
.btn-outline-danger {
  &:hover {
    background-color: white !important;
    color: #f46a6a !important;
  }
}
.btn-outline-danger-delete {
  background-color: white;
  color: #f46a6a !important;
  &:hover {
    background-color: white !important;
    color: #f46a6a !important;
  }
}
.advanced-search {
  display: flex;
  // justify-content: right;
  color: #556ee6;
  padding: 0px 0px 0px 15px;
  h6 {
    color: inherit;
    font-size: 14px;
    // text-transform: uppercase;
    // font-weight: 600;
    cursor: pointer;
  }
}
.search-buttons {
  display: flex;
  align-items: center;
  .reportbutton {
    margin-right: 15px;
    border: none;
    border-radius: 4px;
    padding: 7px 15px;
    background: #34c38f;
    color: white;
    font-size: 12px;
    font-weight: 400;
  }
  .clearsearch {
    color: #2650f6;
    cursor: pointer;
  }
}

.ag-theme-alpine .ag-root-wrapper {
  border: none;
  border-color: #babfc7;
  border-color: var(--ag-border-color, #babfc7);
}
.search-sec {
  padding-left: 25px;
  padding-right: 25px;
}
.ag-theme-alpine {
  // --ag-foreground-color: rgb(126, 46, 132);
  // --ag-background-color: rgb(249, 245, 227);
  // --ag-header-foreground-color: rgb(204, 245, 172);
  // --ag-header-background-color: rgb(209, 64, 129);
  --ag-header-background-color: rgb(247 247 247);
  --ag-odd-row-background-color: rgba(255, 255, 255, 0.03);
  --ag-row-height: 48px;
  // --ag-header-column-resize-handle-color: rgb(126, 46, 132);

  // --ag-font-size: 17px;
  // --ag-font-family: monospace;
}
.ag-watermark {
  display: none;
}
.emptynoti {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 230px;
}
.warehouse_address {
  width: 14rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.react-tel-input .form-control {
  width: 100% !important;
}
.app-search {
  width: 300px;
}
.searchContainer {
  position: relative;
  .autocomplete-component {
    position: absolute;
    border: 1px solid #ececec;
    width: 100%;
    background: white;
    border-top: none;
    max-height: 27em;
    overflow: auto;
    .autocomplete-items {
      cursor: pointer;
      padding: 3px 14px;
      &:hover {
        background: #fafafa;
      }
      .autocomplete-items-text {
        position: inherit;
        color: #383838;
        font-size: 15px;
        font-weight: 600;
      }
      &:focus {
        display: block;
        outline: none;
        background: #dddddd;
      }
    }
  }
}
.invoice-body {
  border: 1px solid black;

  .header {
    background: #f3f3f3;
    padding: 2rem;
    .invoice-header {
      display: flex;
      justify-content: space-between;
      img {
        width: 17rem;
      }
      .title {
        position: absolute;
        right: 8rem;
        width: 14rem;
        height: 12rem;
        top: 20px;
        background: #fbf31a;
        h4 {
          position: absolute;
          bottom: 0;
          font-size: 2rem;
          right: 20%;
          color: black;
        }
      }
    }
  }
  .invoice-main-body {
    padding: 3rem 2rem;
  }
}
.bold {
  font-weight: 600;
}
.warehouse-names {
  display: flex;
  align-items: center;
  gap: 20px;
  .warehouses {
    background: #2650f6;
    font-weight: 600;
    color: #ffffff;
    border-radius: 2px;
    font-size: 12px;
    padding: 0.8em;
  }
}
.pallet-search {
  display: flex;

  gap: 10px;

  .pallet-btn {
    height: 2.8em;
  }
}

.green {
  color: #8fce00;
}
.red {
  color: #ff0000;
}
.orange {
  color: #f1c232;
}

.online-from-container {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  background-color: white;
  height: 95vh;
  padding: 1em 2.5em 1em 2.5em;
  border: 1px solid #dddddd;
  overflow-y: scroll;
  h4 {
    text-align: center;
    text-transform: uppercase;
    margin: 1.2em 0;
    font-weight: 600;
    letter-spacing: 3px;
  }
  .itemSection {
    position: relative;
    .divAddBtn {
      border: none;
      border-radius: 6px;
      background: #4f5cfb;
      font-size: 12px;
      color: white;
      margin-bottom: 2em;
    }
    .itemContainer {
      position: relative;
      .close {
        position: absolute;
        right: 0;
        width: 30px;
        height: 30px;
        background: #e5e5e5;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          color: red !important;
          i {
            color: red !important;
          }
        }
      }
      .addDivBtn {
        border: none;
        border-radius: 6px;
        background: #4f5cfb;
        font-size: 12px;
        color: white;
        padding: 0.5em;
      }
    }
  }
  .form-input-dimension {
    display: flex;
    justify-content: space-between;
  }
  .online-form-submitBtn {
    width: 100%;
    border: none;
    padding: 1em;
    font-weight: 600;
    background: black;
    color: white;
  }
  .user-form-submitBtn {
    width: 40%;
    border: none;
    padding: 1em;
    font-weight: 600;
    background: black;
    color: white;
  }
  .online-form-submitBtn-disabled {
    width: 100%;
    border: none;
    padding: 1em;
    font-weight: 600;
    background: #b2b2b2;
    color: white;
  }

  .opacity {
    opacity: 0;
    &:last-child {
      opacity: 1;
    }
  }
}
.border-bottom {
  border-bottom: 1px solid black !important;
}
.dropdown-menu {
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.35rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 13px;
    &:hover {
      color: #16181b;
      text-decoration: none;
      background-color: #f8f9fa;
    }
  }
}
.approvedBtn {
  background: #4da14d;
  color: white !important;
  padding: 0.5em;
  border: none !important;
  border-radius: 6px;
  font-weight: 500;
}
.denyBtn {
  background: #d74e4e;
  color: white !important;
  border: none !important;
  padding: 0.5em;
  border-radius: 6px;
  font-weight: 500;
}
.waitingBtn {
  background: #f1c232;
  color: white !important;
  border: none !important;
  padding: 0.5em;
  border-radius: 6px;
  font-weight: 500;
}
.newBtn {
  background: #f1c232;
  color: white !important;
  border: none !important;
}
.noborder {
  border: none;
}
.checkout-input {
  border: none;
  border-bottom: 1px solid #052f85;
}
.required {
  border: none;
  border-bottom: 2px solid red;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.mandatory-container {
  background: rgb(255 137 137 / 37%);
  padding: 1.5em 1.6em;
  span {
    font-size: 14px;
    color: black;

    font-weight: 400;
    letter-spacing: 0.05em;
  }
  &.blinker {
    animation: blinker 0.4s linear 3;
  }
}
.mandatory-fields {
  color: #e54949 !important;
  &::after {
    content: "*";
  }
}
.online-note {
  margin-left: 10px;
  margin-bottom: 15px;
}
.card-title {
  font-size: 12px;
  font-weight: 500;
}
.declineModal {
  display: flex;
  margin-top: 1em;
  gap: 1em;
  button {
    border: none;
    padding: 0.5em 2em;
    background: #2650f6;
    color: white;
    &:last-child {
      padding: 0.4em 1.5em;
    }
  }
}
.transaction-container {
}

.pswchange {
  cursor: pointer;
  color: blue;
  &:hover {
    text-decoration: underline;
  }
}
.orderDetailsHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .status {
    padding: 0.5em;
    color: white;
    font-weight: 500;
    &.green {
      background: #60b960;
    }
    &.yellow {
      background: #d9b156;
    }
    &.red {
      background: #f18686;
    }
  }
}
.text-convert {
  background: #32af32;
  margin-bottom: 5px;
  padding: 0.5em 1em;
  color: white;
  font-weight: 500;
  border-radius: 30px;
}
.confirmation-head {
  font-size: 18px;
  font-weight: 500;
}
.confirmation-container {
  margin-bottom: 2.5em;
}
.confirmation-contents {
  border-bottom: 1px solid #e1e1e1;
  font-size: 14px;
  font-weight: 400;
  height: 25px;
}
.conf-items-length {
  background: #1e607d;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: white;
    font-weight: 500;
  }
}
.other-charge-left {
  display: flex;
  .conf-other-item-length {
    background: #1e607d;
    border-radius: 6px;
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-right: 1em;
    span {
      color: white;
      font-weight: 500;
    }
  }
  .other-charge-right {
    width: 100%;
  }
}
.conf-btns {
  display: flex;
  gap: 5px;
  text-align: right;
  .online-buttons {
    border: none;
    padding: 0.85em 2em;
    border-radius: 3px;
    color: white;
    font-weight: 400;
    &.reject {
      background: #d57070;
    }
    &.accept {
      background: #21b585cf no-repeat center center;
    }
  }
}
.page-load-center {
  width: 100%;
  height: 100vh;
  .loading-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    grid-gap: 32px;
    justify-items: center;
  }
}
.inactivity-container {
  width: 100%;
  height: 100vh;
  position: relative;
  .inactivity {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 400;
    color: #459eb3;
    text-align: center;
    .inactivity-text {
      font-size: 2em;
      margin-bottom: 1em;
    }
    .inactivity-text1 {
      font-size: 1.4em;
      margin-bottom: 3em;
    }
    .inactivity-btn {
      background: #5499a9;
      border: none;
      border-radius: 3px;
      color: white;
      padding: 0.7em 1em;
      font-weight: 500;
      font-size: 15px;
    }
  }
}
.checkout-head {
  font-size: 16px;
  font-weight: 500;
  color: #052f85;
  letter-spacing: 0.05em;
  margin-bottom: 3em;
}
.terms {
  width: 100%;
  padding: 3em 3em 0 3em;
  background-color: white;
  img {
    width: 25em;
  }
  .container {
    .heading {
      font-size: 2rem;
      color: black;
      text-align: center;
      margin-bottom: 2em;
    }
    .contents {
      font-size: 16px;
      color: black;
      div {
        margin-bottom: 8px;
      }
      .common-head {
        text-decoration: underline;
        font-weight: 500;
      }
    }
  }
  .term-footer {
    position: relative;
    padding: 3em;
    text-align: center;
    color: #368eb5;
    font-size: 15px;
    // .footer-logo {
    //   position: absolute;
    //   width: 7em;
    //   right: 10em;
    //   top: 2.9em;
    // }
  }
}

.notification-item {
  padding: 15px;
  cursor: pointer;
  &:hover {
    background-color: #f4f4f48c;
  }
}
.table-actionBtn {
  display: flex;
  grid-gap: 0.5em;
  justify-content: end;
  flex: 1;
  margin-right: 15px;
}
.backbutton {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    font-size: 2rem;
  }
  &:hover {
    background: #ededed;
  }
}
.page-header {
  // background: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 85px 30px 30px 30px;
  .title {
    color: #052f85;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .overview {
    color: #0039e4;
  }
  .header-btns {
    display: flex;
    align-items: center;
    gap: 2em;
    position: relative;
    .date-arrange {
      display: flex;
      width: 117px;
      height: 41px;
      flex-direction: column;
      justify-content: center;
      gap: 2px;
      border-radius: 5px;
      box-shadow: 0px 2px 10px 0px #1650ff1f;
      // padding: 4px 13px;
      padding: 0px 0px 0px 10px;

      span {
        font-size: 11px;
        font-weight: 400;
        color: #bababa;
        &:last-child {
          cursor: pointer;
          color: black;
          display: flex;
          align-items: center;
          gap: 3px;
          .bxs-calendar {
            font-size: 10px;
          }
          .bxs-down-arrow {
            font-size: 6px;
          }
        }
      }
    }
    .dropdown {
      position: absolute;
      top: 44px;
      z-index: 9;
      box-shadow: 0px 2px 10px 0px #1650ff1f;
      background-color: #f8f8fb;
      width: 100%;
      padding: 5px 0;
      border-radius: 5px;
      color: black;
      div {
        cursor: pointer;
        padding: 5px 9px 5px 9px;
        border-bottom: 1px solid #1650ff1f;
        &:last-child {
          border: none;
        }
      }
    }
    .refresh-btn {
      cursor: pointer;
      display: flex;
      width: 117px;
      height: 41px;
      justify-content: center;
      align-items: center;
      gap: 7px;
      border-radius: 5px;
      box-shadow: 0px 2px 10px 0px #1650ff1f;
      .bx-refresh {
        color: black;
      }
      span {
        font-size: 12px;
        font-weight: 400;
        color: #bababa;
      }
    }
  }
}
.dashboard_container {
  padding: 30px 15px 30px 15px;
  .dash-cards {
    background: white;
    border-radius: 6px;
    padding: 20px 15px;
    height: 100%;
    .title {
      font-size: 16px;
      color: #2c2c2c;
      font-weight: 500;
      margin-bottom: 1em;
    }
  }
  .account-cards {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .account-card-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-size: 12px;
        font-weight: 400;
        color: #bababa;
      }
      .graph-detail {
        display: flex;
        flex-direction: column;
        .type {
          font-size: 12px;
          font-weight: 400;
          color: #2c2c2c;
        }
        .count {
          font-size: 12px;
          font-weight: 400;
          padding: 2px 10px;
          border-radius: 20px;
          &.green {
            color: #77b900;
            background: #78b90036;
          }
          &.red {
            color: #ed2d22;
            background: #ed2c2236;
          }
        }
      }
    }
    .account-card-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .amount {
        transition: all 0.4s ease-in-out;
        font-size: 20px;
        font-weight: 500;
        color: black;
        @media only screen and (max-width: 1366px) {
          font-size: 14px;
        }
      }
      .account-card-chart {
        // width: 167px;
        // height: 69px;
        // position: relative;
        // top: -24px;
      }
    }
  }
  .cash-flow {
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .graph-filter {
        display: flex;
        align-items: center;
        gap: 1em;
        span {
          cursor: pointer;
          font-size: 14px;
          font-weight: 500;
          color: #838383;
          border-right: 1px solid #838383;
          padding-right: 15px;
          &.active {
            color: #132e80;
            text-decoration: underline;
          }
          &:last-child {
            border: none;
          }
        }
      }
    }
    .row {
      .mixchart {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.5em;
        // height: 100%;
        .mixchart-details {
          display: flex;
          flex-direction: column;
          align-items: center;
          .mixcharttitle {
            font-weight: 600;
            font-size: 16px;
          }
          .mixchartsub {
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .online-from-container {
    width: 90%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .online-from-container {
    width: 90%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .online-from-container {
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .online-from-container {
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .online-from-container {
    width: 50%;
  }
}

@media (max-width: 1290px) {
  .authentication-bottom-links {
    display: block;
    .rememberme {
      margin-bottom: 0.5em;
    }
  }
}
.custom-button {
  padding: 10px;
  width: 100%;
  color: white;
  border: none;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  background-color: white;
  &:hover {
    background-color: #f0f0f0;
  }
}
.react-dropdown {
  position: absolute;
  border: 1px solid #ccc;
  border-radius: 0 0 6px 6px;
  width: 100%;
  background: white;
  // height: 80vh;
  z-index: 999; /* Set a high z-index to ensure the dropdown appears above other content */
}
.dashboard-c {
  margin-left: 250px;
  overflow: hidden;
}
.main-content {
  display: flex;
  height: 100vh;
  background: white;
  .singleTableView {
    padding: calc(60px + 24px) 0 60px 0;
    width: 350px;
    .table-view {
      height: 100%;
      background: grey;
      .table-container {
        .table-head {
          background: darkgrey;
          padding: 1em;
        }
      }
    }
  }
  .page-content {
    width: 100%;
    overflow: auto;
  }
}
.new-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  border: none;
  padding: 0 1.5em;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  background: #3cba00;
  color: white;
  height: 40px;
  .fa {
    font-size: 11px;
  }
}
.common-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5em;
  border-radius: 4px;
  background: #ffffff;
  border: 0.5px solid #aeaeae;
  height: 40px;
  .fa {
    font-size: 18px;
    color: black;
  }
}
.contact-last {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  .form-group {
    width: 100%;
  }
  .contact-btn-a {
    border: none;
    background: white;
    color: green;
  }
  .contact-btn-d {
    border: none;
    background: white;
    color: #ff16168c;
  }
}
