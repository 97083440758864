.component-header {
  font-size: 350%;
  -webkit-text-stroke: 2px #3174ad;
  text-shadow: 5px 5px #558abb;
  color: #f5f5f5;
  font-family: Google Sans, Roboto, Arial, sans-serif;
}

.calendar {
  position: relative;
  z-index: 1;
}
.button-div {
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
  margin-top: 30px;
}
.disable-blur {
  pointer-events: none;
  filter: blur(3px);
}
.add-event-button {
  background-color: #a6bdd6;
  color: white;
  padding: 10px 20px;
  border-radius: 6px;
  border: white;
}

.rbc-calendar {
  background-color: #f5f5f5;
  border-radius: 6px;
  font-family: Arial, sans-serif;
}

.rbc-header {
  background-color: #052f85;
  color: white;
}

.rbc-day-bg {
  background-color: #f5f5f5;
  color: white;
}

.rbc-day-bg.rbc-today {
  background-color: #ffffff;
  color: #052f85;
}

/* event modal */

.event-modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: center;
}

.event-modal-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 20px;
  border-radius: 6px;
}

.event-modal-form input,
.event-modal-form select,
.event-modal-form textarea {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #a6bdd6;
}
.event-modal-form button {
  /* background-color: #a6bdd6; */
  color: white;
  padding: 10px 20px;
  border-radius: 6px;
  border: none;
}
.event-modal-time {
  margin-bottom: 10px;
  border-radius: 6px;
}
.event-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  background-color: #a6bdd6;
  color: #f5f5f5;
  border-radius: 60px;
  padding: 20px;
  /* z-index: 999; */
  width: 20%;
  font-size: 1.5em;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 60px;
  padding: 20px;
  z-index: 999; */
}
.show-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border-radius: 60px;
  padding: 20px;
  z-index: 999;
}
.event-modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.event-modal-title {
  text-decoration-color: aqua;
  /* -webkit-text-stroke: 2px #052f85; */
  /* text-shadow: 5px 5px #558abb; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  flex: 1;
}
.event-modal-header .close {
  background-color: #a6bdd6;
  color: white;
  padding: 10px 20px;
  border-radius: 6px;
  border: white;
}
.close {
  background-color: #a6bdd6;
  color: white;
  padding: 10px 20px;
  border-radius: 6px;
  border: white;
}
.button-container {
  display: flex;
  justify-content: space-around;
}
/* .react-datepicker__input-container {
  display: flex;
  align-items: center;
  width: fit-content;
}

.react-datepicker__input-container input {
  width: 100%;
}

.react-datepicker-time__input {
  width: fit-content  ;
} */
